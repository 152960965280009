import axios from 'axios';
export function getToken() {
	return localStorage.getItem('token')
}
export function getUsuario() {
	return JSON.parse(localStorage.getItem('usuario'))
}
export function getRoles() {
	return localStorage.getItem('roles')
}
export function getSucursal() {
	return localStorage.getItem('Sucursal')
}
export function setUsuario(usr) {
	return localStorage.setItem('usuario', usr)
}
export function setToken(token) {
	return localStorage.setItem('token', token)
}
export function quitarUsuarioToken() {
	localStorage.removeItem('usuario')
	localStorage.removeItem('token')
	localStorage.removeItem('Sucursal')
	localStorage.removeItem('roles')
}

export function initAxiosInterceptors() {
	axios.interceptors.request.use(function (config) {
		const token = getToken();
		if (token) {
			config.headers.Authorization = `bearer ${token}`
		}
		return config;
	})
}

export function getAxiosInterceptors() {
	axios.interceptors.response.use(function (config) {
		return config;
	}, function (error) {
		if (401 === error.response.status) {
			window.location = '/pages/login';
		} else {
			return Promise.reject(error);
		}
	})
}

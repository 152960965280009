import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  /*{
    id: "cotizacion",
    title: "Cotizaciones",
    type: "item",
    icon: <Icon.Folder size={20} />,
    permissions: ["Administrador", "Vendedor"],
    navLink: "/Cotizacion"
  },*/
  {
    id: "cliente",
    title: "Clientes",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["Administrador", "Vendedor","CoordinadorLoguistica"],
    navLink: "/Cliente"
  },
  {
    id: "proveedor",
    title: "Proveedor",
    type: "item",
    icon: <Icon.Box size={20} />,
    permissions: ["Administrador","CoordinadorLoguistica"],
    navLink: "/Proveedor"
  },
  {
    id: "frmcontrato",
    title: "Contratos",
    type: "item",
    icon: <Icon.Activity size={20} />,
    permissions: ["Administrador", "LiderCompras","CoordinadorLoguistica","Vendedor"],
    navLink: "/ContratoAdm"
  },
  {
    id: "cotizacionadm",
    title: "Cotizaciones",
    type: "item",
    icon: <Icon.Award size={20} />,
    permissions: ["Administrador", "Vendedor","CoordinadorLoguistica"],
    navLink: "/CotizacionAdm"
  },
  {
    id: "guiaasesor",
    title: "Comercial Guía",
    type: "item",
    icon: <Icon.Cast size={20} />,
    permissions: ["Administrador", "Vendedor","CoordinadorLoguistica", "JefeOperaciones"],
    navLink: "/GuiaAsesor"
  },
  {
    id: "aceptacionservicio",
    title: "Aceptación",
    type: "item",
    icon: <Icon.Check size={20} />,
    permissions: ["Administrador", "Vendedor","CoordinadorLoguistica"],
    navLink: "/aceptacionservicio"
  },
  {
    id: "admlogistica",
    title: "Logistica",
    type: "item",
    icon: <Icon.Calendar size={20} />,
    permissions: ["Administrador","CoordinadorLoguistica"],
    navLink: "/Logistica"
  },
  /*{
    id: "guia",
    title: "Guias Remision",
    type: "item",
    icon: <Icon.FileText size={20} />,
    permissions: ["Administrador", "AsistenteOperaciones", "JefeOperaciones"],
    navLink: "/Guia"
  },*/
  {
    id: "guias",
    title: "Guias Remision",
    type: "item",
    icon: <Icon.Shuffle size={20} />,
    permissions: ["Administrador", "AsistenteOperaciones", "JefeOperaciones"],
    navLink: "/GuiasRemision"
  },
  /*{
    id: "manifiesto",
    title: "Manifiestos",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["Administrador", "AsistenteOperaciones", "JefeOperaciones"],
    navLink: "/ManifiestoRemision"
  },*/
  {
    id: "manifiestofrm",
    title: "Manifiestos",
    type: "item",
    icon: <Icon.Trello size={20} />,
    permissions: ["Administrador", "AsistenteOperaciones", "JefeOperaciones"],
    navLink: "/Manifiesto"
  },
  /*{
    id: "certificado",
    title: "Certificados",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["Administrador", "AsistenteAdministrativo"],
    navLink: "/CertificadoRemision"
  },*/
  {
    id: "certificados",
    title: "Certificados",
    type: "item",
    icon: <Icon.PenTool size={20} />,
    permissions: ["Administrador", "AsistenteAdministrativo"],
    navLink: "/Certificados"
  },
  {
    id: "certificadoReserva",
    title: "Reserva Certificado",
    type: "item",
    icon: <Icon.Save size={20} />,
    permissions: ["Administrador", "AsistenteAdministrativo"],
    navLink: "/CertificadoReserva"
  },
  /*{
    id: "contrato",
    title: "Contratos",
    type: "item",
    icon: <Icon.FileText size={20} />,
    permissions: ["Administrador", "LiderCompras"],
    navLink: "/Contrato"
  },*/
  {
    id: "firmaElectronica",
    title: "Firma Electrónica",
    type: "item",
    icon: <Icon.AtSign size={20} />,
    permissions: ["Administrador"],
    navLink: "/FirmaElectronica"
  }
]

export default navigationConfig
